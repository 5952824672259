/* You can add global styles to this file, and also import other style files */
.cke_notifications_area {
  display: none !important;
}

.ps-0 {
  padding-inline-start: 0 !important;
}

.swal2-container {
  z-index: 99999 !important;
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
  border-top: 1px solid #ddd !important;
}
